@import 'erpcore/assets/scss/main';

.location {
    &__dropdown {
        left: 0;
        right: 0;
        top: 100%;
        margin: 0;
        padding: 0;
        z-index: 10;
        width: 100%;
        display: block;
        font: $font-form;
        position: absolute;
        border-radius: 3px;
        list-style-type: none;
        background-color: rgba(getColor(white), 1);
        box-shadow: 0 0 6px 0 rgba(49, 69, 141, 0.1);
    }

    &__dropdown-item {
        width: 100%;
        display: block;
    }

    &__dropdown-item--active,
    &__dropdown-item:hover {
        .location__dropdown-btn {
            color: rgba(getColor(mid-grey), 1);
            background-color: rgba(getColor(light-grey), 1);
        }
    }

    &__dropdown-btn {
        margin: 0;
        width: 100%;
        border: none;
        display: block;
        cursor: pointer;
        border-radius: 0;
        text-align: left;
        font: $font-form;
        -webkit-appearance: none;
        transition: getTransition();
        color: getColor(silver-grey);
        background-color: transparent;
        padding: $spacing $spacing * 2;
    }

    &__dropdown-message {
        margin: 0;
        width: 100%;
        border: none;
        display: block;
        border-radius: 0;
        text-align: left;
        font: $font-form;
        -webkit-appearance: none;
        transition: getTransition();
        color: getColor(silver-grey);
        background-color: transparent;
        padding: $spacing $spacing * 2;
    }
}
