@import 'erpcore/assets/scss/main';

.table-actions {
    width: $spacing * 3;
    height: $spacing * 3;
    float: right;
    display: block;
    cursor: pointer;
    position: relative;

    @include breakpoint($to: tablet) {
        float: none;
    }

    &:hover {
        .table-actions__dots {
            background-color: rgba(getColor(light-grey), 1);
        }

        .table-actions__items {
            opacity: 1;
            visibility: visible;
            pointer-events: all;
        }
    }

    &__dots {
        width: 100%;
        height: 100%;
        display: flex;
        border-radius: 100%;
        align-items: center;
        align-content: center;
        justify-content: center;
        transition: getTransition();
        background-color: transparent;

        svg {
            width: $spacing / 2;
            margin: 0 auto;
            display: block;
            fill: rgba(getColor(grey), 1);
        }

        @include breakpoint($to: tablet) {
            display: none;
        }
    }

    &__items {
        top: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        display: flex;
        flex-wrap: nowrap;
        position: absolute;
        visibility: hidden;
        align-items: center;
        pointer-events: none;
        align-content: center;
        list-style-type: none;
        transition: getTransition();
        justify-content: flex-start;
        padding-right: $spacing * 4;
        padding-left: 0;

        @include breakpoint($to: tablet) {
            opacity: 1;
            visibility: visible;
            pointer-events: all;
            padding-right: 0;
        }
    }

    &__item {
        padding-left: $spacing * 1.5;
        background-color: rgba(getColor(white), 1);

        a,
        button,
        .button {
            display: flex;
            cursor: pointer;
            border-radius: 0;
            flex-wrap: nowrap;
            text-align: center;
            align-items: center;
            height: $spacing * 3;
            align-content: center;
            text-decoration: none;
            justify-content: center;
            border: none;
            background-color: transparent;
            padding: 0;
            -webkit-appearance: none;

            svg,
            .button__icon {
                top: 0;
                width: $spacing * 2;
                height: $spacing * 2;
                transition: getTransition();
                fill: rgba(getColor(black), 1);
            }

            .element-loader {
                svg {
                    top: 3px;
                    position: relative;
                    height: auto;
                    margin-right: auto;
                    margin-left: auto;
                }
            }

            &:hover {
                svg {
                    fill: getColor(bordo);
                }
            }
        }
    }
}
