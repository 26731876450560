@font-face {
    font-family: 'Roboto';
    src: url(~erpcore/assets/fonts/Roboto/Roboto-Regular.eot);
    src: url(~erpcore/assets/fonts/Roboto/Roboto-Regular.eot?#iefix) format('embedded-opentype'),
        url(~erpcore/assets/fonts/Roboto/Roboto-Regular.woff2) format('woff2'),
        url(~erpcore/assets/fonts/Roboto/Roboto-Regular.woff) format('woff'),
        url(~erpcore/assets/fonts/Roboto/Roboto-Regular.ttf) format('truetype'),
        url(~erpcore/assets/fonts/Roboto/Roboto-Regular.svg#Roboto-Regular) format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url(~erpcore/assets/fonts/Roboto/Roboto-Medium.eot);
    src: url(~erpcore/assets/fonts/Roboto/Roboto-Medium.eot?#iefix) format('embedded-opentype'),
        url(~erpcore/assets/fonts/Roboto/Roboto-Medium.woff2) format('woff2'),
        url(~erpcore/assets/fonts/Roboto/Roboto-Medium.woff) format('woff'),
        url(~erpcore/assets/fonts/Roboto/Roboto-Medium.ttf) format('truetype'),
        url(~erpcore/assets/fonts/Roboto/Roboto-Medium.svg#Roboto-Medium) format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url(~erpcore/assets/fonts/Roboto/Roboto-Bold.eot);
    src: url(~erpcore/assets/fonts/Roboto/Roboto-Bold.eot?#iefix) format('embedded-opentype'),
        url(~erpcore/assets/fonts/Roboto/Roboto-Bold.woff2) format('woff2'),
        url(~erpcore/assets/fonts/Roboto/Roboto-Bold.woff) format('woff'),
        url(~erpcore/assets/fonts/Roboto/Roboto-Bold.ttf) format('truetype'),
        url(~erpcore/assets/fonts/Roboto/Roboto-Bold.svg#Roboto-Bold) format('svg');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Butler';
    src: url(~erpcore/assets/fonts/Butler/Butler-ExtraBold.eot);
    src: url(~erpcore/assets/fonts/Butler/Butler-ExtraBold.eot?#iefix) format('embedded-opentype'),
        url(~erpcore/assets/fonts/Butler/Butler-ExtraBold.woff2) format('woff2'),
        url(~erpcore/assets/fonts/Butler/Butler-ExtraBold.woff) format('woff'),
        url(~erpcore/assets/fonts/Butler/Butler-ExtraBold.ttf) format('truetype'),
        url(~erpcore/assets/fonts/Butler/Butler-ExtraBold.svg#Butler-ExtraBold) format('svg');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url(~erpcore/assets/fonts/OpenSans/OpenSans-Regular.eot);
    src: url(~erpcore/assets/fonts/OpenSans/OpenSans-Regular.eot?#iefix) format('embedded-opentype'),
        url(~erpcore/assets/fonts/OpenSans/OpenSans-Regular.woff2) format('woff2'),
        url(~erpcore/assets/fonts/OpenSans/OpenSans-Regular.woff) format('woff'),
        url(~erpcore/assets/fonts/OpenSans/OpenSans-Regular.ttf) format('truetype'),
        url(~erpcore/assets/fonts/OpenSans/OpenSans-Regular.svg#OpenSans-ExtraBold) format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url(~erpcore/assets/fonts/OpenSans/OpenSans-SemiBold.eot);
    src: url(~erpcore/assets/fonts/OpenSans/OpenSans-SemiBold.eot?#iefix)
            format('embedded-opentype'),
        url(~erpcore/assets/fonts/OpenSans/OpenSans-SemiBold.woff2) format('woff2'),
        url(~erpcore/assets/fonts/OpenSans/OpenSans-SemiBold.woff) format('woff'),
        url(~erpcore/assets/fonts/OpenSans/OpenSans-SemiBold.ttf) format('truetype'),
        url(~erpcore/assets/fonts/OpenSans/OpenSans-SemiBold.svg#OpenSans-SemiBold) format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url(~erpcore/assets/fonts/OpenSans/OpenSans-Bold.eot);
    src: url(~erpcore/assets/fonts/OpenSans/OpenSans-Bold.eot?#iefix) format('embedded-opentype'),
        url(~erpcore/assets/fonts/OpenSans/OpenSans-Bold.woff2) format('woff2'),
        url(~erpcore/assets/fonts/OpenSans/OpenSans-Bold.woff) format('woff'),
        url(~erpcore/assets/fonts/OpenSans/OpenSans-Bold.ttf) format('truetype'),
        url(~erpcore/assets/fonts/OpenSans/OpenSans-Bold.svg#OpenSans-Bold) format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url(~erpcore/assets/fonts/OpenSans/OpenSans-ExtraBold.eot);
    src: url(~erpcore/assets/fonts/OpenSans/OpenSans-ExtraBold.eot?#iefix)
            format('embedded-opentype'),
        url(~erpcore/assets/fonts/OpenSans/OpenSans-ExtraBold.woff2) format('woff2'),
        url(~erpcore/assets/fonts/OpenSans/OpenSans-ExtraBold.woff) format('woff'),
        url(~erpcore/assets/fonts/OpenSans/OpenSans-ExtraBold.ttf) format('truetype'),
        url(~erpcore/assets/fonts/OpenSans/OpenSans-ExtraBold.svg#OpenSans-ExtraBold) format('svg');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url(~erpcore/assets/fonts/OpenSans/OpenSans-Light.eot);
    src: url(~erpcore/assets/fonts/OpenSans/OpenSans-Light.eot?#iefix) format('embedded-opentype'),
        url(~erpcore/assets/fonts/OpenSans/OpenSans-Light.woff2) format('woff2'),
        url(~erpcore/assets/fonts/OpenSans/OpenSans-Light.woff) format('woff'),
        url(~erpcore/assets/fonts/OpenSans/OpenSans-Light.ttf) format('truetype'),
        url(~erpcore/assets/fonts/OpenSans/OpenSans-Light.svg#Butler-Light) format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gelion';
    src: url(~erpcore/assets/fonts/Gelion/Gelion-Regular.eot);
    src: url(~erpcore/assets/fonts/Gelion/Gelion-Regular.eot?#iefix) format('embedded-opentype'),
        url(~erpcore/assets/fonts/Gelion/Gelion-Regular.woff2) format('woff2'),
        url(~erpcore/assets/fonts/Gelion/Gelion-Regular.woff) format('woff'),
        url(~erpcore/assets/fonts/Gelion/Gelion-Regular.ttf) format('truetype'),
        url(~erpcore/assets/fonts/Gelion/Gelion-Regular.svg#Gelion-Regular) format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Gelion';
    src: url(~erpcore/assets/fonts/Gelion/Gelion-Bold.eot);
    src: url(~erpcore/assets/fonts/Gelion/Gelion-Bold.eot?#iefix) format('embedded-opentype'),
        url(~erpcore/assets/fonts/Gelion/Gelion-Bold.woff2) format('woff2'),
        url(~erpcore/assets/fonts/Gelion/Gelion-Bold.woff) format('woff'),
        url(~erpcore/assets/fonts/Gelion/Gelion-Bold.ttf) format('truetype'),
        url(~erpcore/assets/fonts/Gelion/Gelion-Bold.svg#Gelion-Bold) format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Gelion';
    src: url(~erpcore/assets/fonts/Gelion/Gelion-SemiBold.eot);
    src: url(~erpcore/assets/fonts/Gelion/Gelion-SemiBold.eot?#iefix) format('embedded-opentype'),
        url(~erpcore/assets/fonts/Gelion/Gelion-SemiBold.woff2) format('woff2'),
        url(~erpcore/assets/fonts/Gelion/Gelion-SemiBold.woff) format('woff'),
        url(~erpcore/assets/fonts/Gelion/Gelion-SemiBold.ttf) format('truetype'),
        url(~erpcore/assets/fonts/Gelion/Gelion-SemiBold.svg#Gelion-SemiBold) format('svg');
    font-weight: 700;
    font-style: normal;
}
