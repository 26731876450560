@import 'erpcore/assets/scss/main';

.repeater {
    $repeater: &;
    position: relative;
    display: block;
    width: 100%;

    &__loader {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        max-width: 100%;
        height: 100%;
        background-color: rgba(getColor(white), 0.5);
        z-index: 3;
    }

    &__list {
        position: relative;
        display: block;
        width: 100%;

        &:before {
            content: " ";
            position: absolute;
            bottom: -6px;
            top: -6px;
            left: -6px;
            right: -6px;
            display: block;
            border: 4px dashed rgba(getColor(bright-grey), 1);
            background: rgba(getColor(light-grey), 0.05);
            pointer-events: none;
            opacity: 0;
            transition: getTransition();
        }

        #{$repeater}--dragging & {
            cursor: grabbing;

            &:before {
                opacity: 1;
            }
        }

        #{$repeater}--loading & {
            pointer-events: none;
        }
    }

    &__item {
        $item: &;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        max-width: 100%;
        border-radius: 3px;
        border: 2px solid rgba(getColor(light-grey), 1);
        padding: $spacing * 2 $spacing * 5 $spacing * 2 $spacing * 5;
        margin: 0 0 $spacing * 2;
        background: rgba(getColor(white), 1);
        opacity: 1;

        #{$repeater}--dragging &:not(&--dragged) {
            opacity: 0.5;
        }

        &--dragged {
            z-index: 999;
        }

        &-move {
            position: absolute;
            top: 18px;
            left: 4px;
            padding: $spacing 5px;
            cursor: grab;

            #{$repeater}--dragging & {
                cursor: grabbing;
            }

            #{$repeater}--no-sort & {
                display: none;
            }

            &-icon {
                display: block;
                width: 20px;
                height: 20px;
                fill: rgba(getColor(grey), 1);

                svg {
                    display: block;
                    width: 20px;
                    height: 20px;
                    margin: 0;

                    #{$item}--dragged & {
                        // fill: getColor(water-green);
                    }
                }
            }
        }

        &-cancel {
            position: absolute;
            top: 22px;
            right: 5px;
            padding: $spacing;

            .svg {
                display: block;
                width: 12px;
                height: 12px;
            }

            svg {
                width: 12px;
                height: 12px;
                fill: rgba(getColor(dark-grey), 1) !important;
            }

            &:hover {
                svg {
                    fill: rgba(getColor(tomato), 1) !important;
                }
            }
        }

        &-content {
            display: block;
            width: 100%;

            #{$item}--dragged & {
                // opacity: 0.5;
            }

            &-title {
                margin: 11px 0 ($spacing * 2);
                font: $font-3;
            }
        }

        &-remove {
            position: absolute;
            top: 20px;
            right: 5px;
            margin: 0;

            svg {
                fill: rgba(getColor(dark-grey), 1) !important;
            }

            &:hover {
                svg {
                    fill: rgba(getColor(tomato), 1) !important;
                }
            }
        }
    }

    &__add-new {
        &--right {
            float: right;
            padding-right: 0;
        }
    }

    .collapse {
        &__toggle {
            // right: -($spacing/2);
        }
    }

    &--loading {
        cursor: progress;
    }

    &--no-sort {
        #{$repeater}__item {
            padding-left: $spacing * 2;
        }
    }

    &--no-right-padding {
        #{$repeater}__item {
            padding-right: $spacing * 2;
        }
    }
}
