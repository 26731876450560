@import 'erpcore/assets/scss/main';

.tooltip {
    display: inline-block;

    &-content {
        height: 0px;
        line-height: 0px;
    }
}

.react-tooltip-lite {
    background: rgba(getColor(black), 1);
    color: rgba(getColor(white), 1);
    font: $font-body;
    border-radius: 3px;
    box-shadow: 0px 3px 6px 0 rgba(10, 9, 12, 0.19);
    margin-top: 3px;
}

.react-tooltip-lite-arrow {
    margin-top: 3px;
    border-color: rgba(getColor(black), 1);
}
