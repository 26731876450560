@import 'erpcore/assets/scss/main';

.tabs {
    $this: &;
    width: 100%;
    font: $font-4;
    display: block;
    color: rgba(getColor(black), 1);
    padding: 0px 0px ($spacing * 4) 0px;

    &__list {
        margin: 0;
        padding: 0;
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        list-style-type: none;
        align-items: flex-start;
        align-content: flex-start;
        justify-content: flex-start;
        overflow-x: auto;
        overflow-y: hidden;

        &-item {
            padding: 0px;
        }
    }

    &__link {
        display: block;
        width: 100%;
        text-decoration: none;
        color: rgba(getColor(dark-grey), 1);
        padding: 0px ($spacing * 2);
        white-space: nowrap;

        #{$this}__list-item:first-child & {
            padding-left: 0px;
        }

        &:hover {
            text-decoration: none;
            color: rgba(getColor(denim), 0.7);
        }

        &--active {
            color: rgba(getColor(denim), 1);
            cursor: default;

            &:hover {
                color: rgba(getColor(denim), 1);
            }
        }
    }
}
