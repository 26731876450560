@import 'erpcore/assets/scss/main';

.accordion {
    border-radius: 3px;
    margin-bottom: $spacing * 3;
    border: 2px solid rgba(getColor(light-grey), 1);

    &:last-child {
        margin-bottom: 0;
    }

    &__header {
        padding: $spacing * 2 $spacing * 3;
        border-bottom: 1px solid rgba(getColor(light-grey), 1);

        &-title {
            font: $font-2;
            margin-bottom: 0;
        }
    }

    &__content {
        list-style-type: none;
        padding: 0;
    }

    &__item {
        position: relative;

        &:before {
            content: ' ';
            display: block;
            position: absolute;
            top: 0;
            left: $spacing * 2;
            right: 0px;
            height: 1px;
            background: rgba(getColor(light-grey), 1);
        }

        &:first-child {
            &:before {
                content: none;
            }
        }

        &-header {
            position: relative;
            cursor: pointer;
            width: 100%;
            text-align: left;
            padding: ($spacing * 2) 0;
            background-image: none;
            background-color: transparent;
            border: none;
            outline: 0;

            &-actions {
                position: absolute;
                top: 11px;
                right: $spacing * 4;
                width: auto;

                button {
                    .svg {
                        svg {
                            fill: getColor(main-grey);
                        }
                    }
                }
            }
        }

        &-action {
            display: block;
            position: absolute;
            right: $spacing * 2;
            top: 18px;
            width: $spacing;
            border: 0;
            margin: 0;
            padding: 0;
            background: transparent;
            outline: 0;

            &-icon {
                width: $spacing;
                height: $spacing;
                transition: getTransition();
            }
        }

        &-content {
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.3s;

            &-inner {
                opacity: 0;
                transform: translateY(-1rem);
                transition: opacity 0.1s linear 0.3s, transform 0.1s ease 0.3s;
                padding: 0 ($spacing * 2) ($spacing * 2);
            }
        }

        &-title {
            font: $font-4;
            margin-bottom: 0;
            margin-right: $spacing * 4;
            padding: 0 ($spacing * 2) 0 ($spacing * 2);
        }

        &--active {
            .accordion__item {
                &-action {
                    &-icon {
                        transform: rotate(-180deg);
                    }
                }

                &-content {
                    max-height: 100rem;
                    transition: max-height 0.3s cubic-bezier(0.895, 0.03, 0.685, 0.22);

                    &-inner {
                        opacity: 1;
                        transform: translateY(0);
                        transition: opacity 0.2s ease-in-out 0.2s, transform 0.2s ease-in-out 0.2s;
                    }
                }

                &-title {
                    color: rgba(getColor(black), 1);
                }
            }
        }

        &--thin {
            .accordion__item-title {
                font: $font-body;
            }
        }

        &--header-actions {
            .accordion__item-title {
                padding-right: $spacing * 4;
            }
        }
    }

    &--grey {
        background: rgba(getColor(light-grey), 1);

        .accordion__item {
            &:before {
                background: rgba(getColor(white), 1);
            }
        }
    }
}
