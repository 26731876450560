@import 'erpcore/assets/scss/main';

.listing {
    $listing: &;
    position: relative;
    width: 100%;
    display: block;
    margin-bottom: $spacing * 5;

    &__header {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        align-content: center;
        margin-bottom: $spacing * 2;
        justify-content: space-between;

        &:last-child {
            margin-bottom: -$spacing * 3;

            @include breakpoint(mobile) {
                margin-bottom: -$spacing * 5;
            }
        }

        @include breakpoint(tablet) {
            flex-wrap: wrap;
        }

        & > .listing__header-col {
            @include breakpoint(tablet) {
                padding-bottom: $spacing * 2;
            }
        }
    }

    &__header-col {
        display: flex;
        flex: 0 0 auto;
        align-items: center;
        align-content: center;
        justify-content: flex-start;
        padding-right: $spacing * 3;
        min-height: $spacing * 4;

        @include breakpoint(tablet) {
            padding-right: $spacing;
        }

        &--main {
            @include breakpoint(tablet) {
                width: 100%;
                flex: 1 1 100%;
                padding-right: 0;
            }
        }

        &--filter {
            width: $spacing * 3;
            flex: 0 0 $spacing * 3;
        }

        &--aside {
            flex: 0 0 auto;
            flex-wrap: wrap;
            padding-right: 0;

            @include breakpoint(tablet) {
                width: 100%;
                flex: 1 1 100%;
                padding-bottom: 0 !important;
            }

            & > * {
                @include breakpoint(tablet) {
                    margin-bottom: $spacing * 2;
                }
            }
        }

        &--space-between {
            flex: 1 1 auto;
            justify-content: space-between;

            & > * {
                margin-right: $spacing * 2;
            }

            & > *:last-child {
                margin-right: 0;
            }
        }

        &--search {
            flex: 1 1 auto;
            padding-right: 0;
        }
    }

    &__body {
        margin-bottom: $spacing * 3;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__footer {
        width: 100%;
        display: flex;
        font: $font-list;
        flex-wrap: nowrap;
        align-items: center;
        align-content: center;
        color: getColor(silver-grey);
        justify-content: space-between;

        @include breakpoint($to: tablet) {
            width: 100%;
            flex-direction: column;
        }
    }
}
