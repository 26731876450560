:root {
    --titleFont: 'Butler';
    --bodyFont: 'Montserrat';
}

$font_family: var(--bodyFont), Helvetica, Arial, sans-serif;
$font_family_butler: var(--titleFont), Helvetica, Arial, sans-serif;

$font-1: 700 26px/26px $font_family_butler;
$font-2: 700 18px/22px $font_family;
$font-3: 700 16px/20px $font_family;
$font-4: 700 14px/18px $font_family;
$font-5: 700 12px/18px $font_family;

$font-button: 500 14px/18px $font_family;

$font-form: 400 16px/20px $font_family;
$font-body: 400 13px/18px $font_family;
$font-list: 400 12px/18px $font_family;
$font-label: 400 11px/16px $font_family;

$font-caption: 700 10px/13px $font_family;
