@import 'erpcore/assets/scss/main';

.error-404 {
    width: 100%;
    display: flex;
    flex: {
        direction: column;
        grow: 1;
    }

    &__wrapper {
        position: relative;
        margin: auto;
        width: 100%;
        max-width: $spacing * 54;
    }

    &__icon {
        display: block;
        width: 100%;
        height: auto;
    }

    &__title {
        margin: 0 0 ($spacing * 2) 0;
        font: $font-form;
        color: rgba(getColor(black), 1);
    }

    &__content {
        display: inline-block;
        position: absolute;
        bottom: $spacing * 5;
        left: 0;

        @include breakpoint($to: tablet) {
            bottom: 0;
        }
    }
}
