@import 'erpcore/assets/scss/main';

.input-radio {
    $this: &;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start;
    margin-bottom: $spacing;
    margin-top: $spacing;
    margin-right: $spacing * 2;

    &:last-child {
        margin-right: 0;
    }

    &__error {
        font: $font-label;
        margin-left: $spacing * 2;
        margin-top: $spacing;
        color: rgba(getColor(tomato), 1);
    }

    &__label {
        display: block;
        width: 100%;
        cursor: pointer;
        font: $font-body;
        padding-left: 26px;
        position: relative;
        // color: rgba(getColor(black), 1);

        &:before {
            left: 0;
            top: -1px;
            z-index: 1;
            width: ($spacing * 2);
            height: ($spacing * 2);
            content: '\A';
            position: absolute;
            border-radius: 100%;
            background-color: rgba(getColor(white), 1);
            border: solid 2px rgba(getColor(light-grey), 1);
        }

        &:after {
            top: 4px;
            left: 5px;
            z-index: 2;
            width: 10px;
            height: 10px;
            content: '\A';
            position: absolute;
            border-radius: 100%;
            transform: scale(0);
            transform-origin: center;
            transition: getTransition();
            background-color: rgba(getColor(mid-grey), 1);
        }
    }

    input {
        display: none;

        &:checked + #{$this}__label {
            &:after {
                transform: scale(1);
            }
        }

        &:disabled + #{$this}__label {
            pointer-events: none;
            opacity: 0.6;

            &:before {
                background-color: rgba(getColor(bright-grey), 1);
            }
        }
    }
}
