@import 'erpcore/assets/scss/main';

.form--permissions-toggle {
    position: relative;
    display: flex;
    min-width: 100px;

    .input-switch {
        margin: 0 auto;

        @include breakpoint(tablet) {
            margin: 0 0 $spacing 0;
        }
    }

    .element-loader {
        background-color: rgba(255, 255, 255, 0.8);
    }
}
