@import 'erpcore/assets/scss/main';

.image-upload {
    $this: &;

    &__drop-area {
        position: relative;
        border-radius: 3px;
        border: 2px dashed rgba(getColor(light-grey), 1);
        text-align: center;
        padding: $spacing;
        font: $font-2;
        color: rgba(getColor(mid-grey), 1);

        &-button {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            margin-right: $spacing;
        }

        input {
            display: none;
        }
    }

    &__preview {
        $preview: &;
        overflow: hidden;
        margin-top: $spacing * 2;
        margin-right: $spacing * 2;
        display: inline-block;
        width: 120px;
        height: 120px;
        background-size: cover;
        background-position: center center;
        border-radius: 3px;

        &:hover {
            #{$preview}-overlay {
                opacity: 1;
            }
        }

        &-overlay {
            display: flex;
            opacity: 0;
            height: 100%;
            align-items: center;
            justify-content: center;
            background-color: rgba(getColor(black), 0.8);

            &-action {
                cursor: pointer;
                fill: rgba(getColor(white), 1);
                background: transparent;
                border: none;
            }
        }
    }
}
