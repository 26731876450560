@import 'erpcore/assets/scss/main';

.pagination {
    $_button_size: 26px;

    color: rgba(getColor(mid-grey), 1);

    @include breakpoint($to: tablet) {
    }

    &__button {
        font: $font-list;
        color: rgba(getColor(mid-grey), 1);
        padding: 0;
        position: relative;
        min-width: $_button_size;
        background: transparent;
        border: none;
        z-index: 2;
        line-height: $_button_size;
        text-align: center;
        cursor: pointer;
        transition: getTransition();

        .svg {
            width: $spacing;
            fill: rgba(getColor(black), 1);
        }

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            opacity: 1;
            min-width: $_button_size;
            height: $_button_size;
            border-radius: $_button_size / 2;
            background: rgba(getColor(light-grey), 1);
            z-index: -1;
            transform: scale(0);
            transition: getTransition();
        }

        &--arrow {
            margin: 0 $spacing / 2;
            color: rgba(getColor(black), 1);

            svg {
                transition: getTransition();
            }

            &:hover {
                &:after {
                    // opacity: 0.5;
                }

                svg {
                    fill: rgba(getColor(dark-grey), 1);
                }
            }
        }

        &--active {
            font-weight: bold;
            color: rgba(getColor(dark-grey), 1);
        }

        &--arrow,
        &--active {
            &:after {
                transform: scale(1);
            }
        }

        &--disabled {
            pointer-events: none;
        }

        &:hover {
            color: rgba(getColor(dark-grey), 1);
            cursor: pointer;
        }
    }
}
