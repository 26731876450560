@import 'erpcore/assets/scss/main';

.showing-results {
    $_button_size: 26px;

    display: flex;
    align-items: center;
    justify-content: left;
    font: $font-list;
    color: rgba(getColor(grey), 1);

    @include breakpoint($to: tablet) {
        margin-bottom: $spacing * 3;
    }

    &__per-page {
        &-button {
            font: $font-list;
            color: rgba(getColor(mid-grey), 1);
            padding: 0;
            position: relative;
            min-width: $_button_size;
            margin-left: $spacing;
            background: transparent;
            border: none;
            z-index: 2;
            line-height: $_button_size;
            text-align: center;
            cursor: pointer;
            transition: getTransition();

            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                width: 100%;
                opacity: 1;
                min-width: $_button_size;
                height: $_button_size;
                transition: getTransition();
                border-radius: $_button_size / 2;
                background-color: rgba(getColor(light-grey), 1);
                z-index: -1;
            }

            &--arrow {
                margin: 0 $spacing / 2;
            }

            &--active {
                font-weight: bold;
                color: rgba(getColor(dark-grey), 1);
            }

            &--disabled {
                pointer-events: none;
            }

            &:hover {
                color: rgba(getColor(dark-grey), 1);

                &:after {
                    // opacity: 0.5;
                }
            }
        }

        &-text {
            font: $font-list;
            margin-left: $spacing;
            color: rgba(getColor(mid-grey), 1);
        }
    }
}
