@import 'erpcore/assets/scss/main';

.page-header {
    $this: &;
    position: relative;
    display: flex;
    width: 100%;
    min-height: $spacing * 11;
    align-items: center;
    justify-content: space-between;
    min-height: $spacing * 10;
    padding: ($spacing * 3) 0;

    &__title {
        flex: 1;
        font: $font-1;
        margin: 0 0 $spacing 0;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__subtitle {
        margin-bottom: 0;
    }

    &__content {
        margin-right: auto;
    }

    &__back {
        height: 100%;
        padding-top: 0;
        margin-left: -($spacing * 2);
        display: flex;
        align-items: center;

        @include breakpoint($from: tablet) {
            position: absolute;
            left: -($spacing * 7);
            bottom: 0;
            margin-left: 0;
            z-index: 10;
        }

        &-button {
            background-color: transparent;
            border: none;
            color: rgba(getColor(black), 1);
            fill: rgba(getColor(black), 1);

            &:hover {
                background-color: transparent;
                border: none;
                color: rgba(getColor(black), 1);
                fill: rgba(getColor(black), 1);
            }

            .button__icon {
                fill: rgba(getColor(black), 1);

                svg {
                    fill: rgba(getColor(black), 1);
                }
            }
        }
    }

    &__favorite {
        width: $spacing * 3;
        height: $spacing * 3;
        display: inline-block;
        user-select: none;
        touch-action: manipulation;
        white-space: nowrap;
        cursor: pointer;
        padding: 0;
        margin: 0 auto 0 ($spacing * 2);
        border: none;
        background-color: transparent;
        transition: getTransition();

        .svg {
            display: block;
            width: 100%;
            height: auto;

            svg {
                display: block;
                fill: rgba(getColor(bright-grey), 1);
                transition: getTransition();
            }
        }

        &:hover {
            svg {
                fill: rgba(getColor(sun), 1);
            }
        }

        &:focus {
            svg {
                fill: rgba(getColor(sun), 1);
            }
        }
    }

    &__actions {
        display: flex;

        &-dots {
            width: 100%;
            height: 100%;
            display: flex;
            border-radius: 100%;
            align-items: center;
            align-content: center;
            justify-content: center;
            transition: getTransition();
            background-color: transparent;

            svg {
                width: $spacing / 2;
                margin: 0 auto;
                display: block;
                fill: getColor(silver-grey);
            }

            @include breakpoint($from: tablet) {
                display: none;
            }
        }

        @include breakpoint($to: tablet) {
            width: $spacing * 3;
            height: $spacing * 5;
            float: right;
            display: block;
            cursor: pointer;
            position: relative;
            align-self: flex-start;

            &:hover {
                .page-header__actions-dots {
                    background-color: rgba(getColor(light-grey), 1);
                }

                .page-header__actions-items {
                    opacity: 1;
                    visibility: visible;
                    pointer-events: all;
                }
            }
        }

        &-items {
            display: flex;

            @include breakpoint($to: tablet) {
                height: $spacing * 5;
                background: rgba(getColor(white), 1);
                top: 50%;
                transform: translateY(-50%);
                right: 0;
                bottom: 0;
                opacity: 0;
                display: flex;
                flex-wrap: nowrap;
                position: absolute;
                visibility: hidden;
                align-items: center;
                pointer-events: none;
                align-content: center;
                list-style-type: none;
                transition: getTransition();
                justify-content: flex-start;
            }
        }
    }
}
