@import 'erpcore/assets/scss/main';

.employment-history-record {

    &__item {
        display: flex;
        width: 100%;
        padding: $spacing 0;
        border-top: 1px solid rgba(getColor(bright-grey), 1);
    }

    &__title {
        flex: 0 0 28%;
        margin: 0;
        font: $font-body;
        text-align: right;
        color: getColor(silver-grey);
    }

    &__value {
        flex: 0 0 72%;
        margin: 0;
        padding-left: ($spacing * 2);
        font: $font-body;
    }
}
