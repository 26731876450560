@import 'erpcore/assets/scss/main';

.form {
    &__row {
        &--user-global-permissions {
            .input-checkbox {
                width: calc(33.333333% - (#{$spacing} * 2));

                @include breakpoint(mobile) {
                    width: calc(50% - (#{$spacing} * 2));
                }

                @include breakpoint(small) {
                    width: 100%;
                }
            }
        }
    }
}
