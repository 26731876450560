@import 'erpcore/assets/scss/main';

.input-checkbox {
    $this: &;
    display: flex;
    margin-bottom: $spacing;
    margin-top: $spacing;
    margin-right: $spacing * 2;

    &:last-child {
        margin-right: 0;
    }

    &__error {
        font: $font-label;
        margin-left: $spacing * 2;
        margin-top: $spacing;
        color: rgba(getColor(tomato), 1);
    }

    &__label {
        display: flex;
        font: $font-body;
        cursor: pointer;
        flex-wrap: nowrap;
        position: relative;
        flex-direction: row;
        align-items: flex-start;
        align-content: flex-start;
        justify-content: flex-start;
    }

    &__icon {
        width: 16px;
        height: 16px;
        margin-right: 8px;
        border-radius: 3px;
        transition: getTransition();
        background-color: rgba(getColor(white), 1);
        border: 2px solid rgba(getColor(light-grey), 1);

        .svg {
            width: auto;
            height: auto;
            display: block;
        }

        svg {
            width: $spacing;
            margin-left: 2px;
            height: 12px;
            display: block;
            fill: rgba(getColor(white), 1);
            opacity: 0;
            transform: scale(2);
            transition: getTransition();
        }
    }

    input {
        display: none;

        &:checked + #{$this}__label {
            #{$this}__icon {
                border: 2px solid rgba(getColor(mid-grey), 1);
                background-color: rgba(getColor(mid-grey), 1);

                svg {
                    opacity: 1;
                    transform: scale(1) translateY(-2px);
                }
            }
        }

        &:disabled + #{$this}__label {
            pointer-events: none;
            opacity: 0.6;

            #{$this}__icon {
                border: 2px solid rgba(getColor(bright-grey), 1);
                background-color: rgba(getColor(bright-grey), 1);

                svg {
                    opacity: 0;
                    transform: scale(0);
                }
            }
        }
    }
}
