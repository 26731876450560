@import 'erpcore/assets/scss/main';

.bulk-actions {
    // Checkbox
    &-checkbox {
        $this: &;
        display: flex;
        margin-bottom: $spacing;
        margin-top: $spacing;
        margin-right: $spacing * 2;

        &:last-child {
            margin-right: 0;
        }

        &__error {
            font: $font-label;
            margin-left: $spacing * 2;
            margin-top: $spacing;
            color: rgba(getColor(tomato), 1);
        }

        &__label {
            display: flex;
            font: $font-button;
            cursor: pointer;
            flex-wrap: nowrap;
            position: relative;
            flex-direction: row;
            align-items: flex-start;
            align-content: flex-start;
            justify-content: flex-start;
        }

        &__icon {
            width: 16px;
            height: 16px;
            margin-right: 8px;
            border-radius: 3px;
            transition: getTransition();
            background-color: rgba(getColor(white), 1);
            border: 2px solid rgba(getColor(light-grey), 1);

            .svg {
                width: auto;
                height: auto;
                display: block;
            }

            svg {
                width: $spacing;
                margin-left: 2px;
                height: 12px;
                display: block;
                fill: rgba(getColor(white), 1);
                opacity: 0;
                transform: scale(2);
                transition: getTransition();
            }
        }

        input {
            display: none;

            &:checked + #{$this}__label {
                #{$this}__icon {
                    border: 2px solid rgba(getColor(mid-grey), 1);
                    background-color: rgba(getColor(mid-grey), 1);

                    svg {
                        opacity: 1;
                        transform: scale(1);
                    }
                }
            }

            &:disabled + #{$this}__label {
                pointer-events: none;
                opacity: 0.6;

                #{$this}__icon {
                    border: 2px solid rgba(getColor(light-grey), 1);
                    background-color: rgba(getColor(light-grey), 1);
                }
            }
        }
    }
    // Dropdown
    &-dropdown {
        $dropdown: &;
        position: relative;
        display: inline-block;
        flex: 0 1 auto;
        align-items: center;
        max-width: 100%;
        height: 28px;
        margin: 0;
        border: none;
        border-radius: 3px;
        font: $font-list;
        text-transform: none;
        text-align: center;
        text-decoration: none;
        color: rgba(getColor(white), 1);
        fill: rgba(getColor(white), 1);
        background-image: none;
        vertical-align: middle;
        cursor: pointer;
        transition: getTransition();
        padding: 0;
        margin-right: $spacing;

        &__toggle {
            border: none;
            font: inherit;
            text-transform: inherit;
            text-align: inherit;
            text-decoration: none;
            color: inherit;
            background-image: none;
            background-color: transparent;
            border-radius: 3px;
            vertical-align: inherit;
            user-select: inherit;
            touch-action: inherit;
            cursor: inherit;
            white-space: inherit;
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: getTransition();
            padding: 5px $spacing;
            background-color: getColor(bordo);

            &:hover,
            &:active,
            &:focus {
                text-decoration: none;
                color: rgba(getColor(white), 1);
                background: getColor(bordo-hover);
            }

            &-label {
                display: inline-block;
                margin-right: $spacing;

                &:last-child {
                    margin-right: 0;
                }
            }

            &-icon {
                display: block;
                width: 10px;
                height: 10px;
                transition: getTransition();

                #{$dropdown}--open & {
                    transform: rotate(180deg);
                }

                svg {
                    display: block;

                    fill: rgba(getColor(white), 1);
                }
            }

            &:disabled {
                color: rgba(getColor(white), 1);
                background: getColor(silver-grey);
            }
        }

        .react-select {
            &__control {
                display: none !important;
            }

            &__option {
                color: getColor(silver-grey);

                &:hover {
                    color: getColor(purple-hover);
                }
            }

            &__option--is-focused {
                color: getColor(silver-grey);
                background-color: transparent;
            }

            &__option--is-selected {
                color: getColor(silver-grey);
                background-color: rgba(getColor(light-grey), 1);
            }

            &__menu {
                top: 100%;
                font: $font-list;
            }

            &__option {
                padding: $spacing 12px;
            }
        }
    }
    // Modal
    /*
    &__modal {
        .modal__title {
            // font: $font-2;
        }
    }
    */
}
