@import 'erpcore/assets/scss/main';

.location {
    flex: 1;
    display: block;
    width: 100%;
    position: relative;
    margin: 0 $spacing * 2 0 0;

    &:last-child {
        margin-right: 0px;
    }

    &__checkbox {
        width: 100%;
        display: block;
        margin-bottom: $spacing * 1.5;

        & > * {
            margin: 0;
        }
    }

    &__helper-text {
        position: relative;
        bottom: -($spacing / 2);
        display: inline-block;
        width: 100%;
        font: $font-label;
        padding-left: $spacing * 2;
        margin-top: 0px;
        color: rgba(getColor(mid-grey), 1);

        &--error {
            color: rgba(getColor(tomato), 1);
        }
    }
}
