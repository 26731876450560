@import 'erpcore/assets/scss/main';

.input-switch {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    margin-bottom: $spacing * 2;

    &__error {
        font: $font-label;
        width: 100%;
        margin-left: $spacing * 2;
        margin-top: $spacing;
        color: rgba(getColor(tomato), 1);
    }

    &__col {
        padding-right: 7px;

        &:last-child {
            padding-right: 0;
        }
    }

    &__toggle {
        width: 32px;
        height: 16px;
        display: block;
        font-size: 13px;
        cursor: pointer;
        border-radius: 8px;
        position: relative;
        background-color: white;
        transition: getTransition();
        border: 2px solid rgba(getColor(light-grey), 1);

        &:before {
            top: -2px;
            left: -2px;
            z-index: 2;
            width: 16px;
            height: 16px;
            content: '\A';
            position: absolute;
            border-radius: 100%;
            transform: translateX(0px);
            transition: getTransition();
            background-color: rgba(getColor(mid-grey), 1);
        }
    }

    &__toggle--active {
        &:before {
            transform: translateX(16px);
        }
    }

    &__toggle--green {
        border: 2px solid rgba(getColor(apple), 1);
        background-color: rgba(getColor(apple), 1);
    }

    &__checkbox {
        display: none;
    }

    &__option {
        cursor: pointer;
        display: block;
        transition: getTransition();
        color: getColor(silver-grey);
    }

    &__option--active {
        color: rgba(getColor(black), 1);
    }

    &__option--clickable {
        cursor: pointer;
    }
}
