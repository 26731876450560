@import 'erpcore/assets/scss/main';

.status-circle {
    $root: &;
    display: inline-block;
    width: $spacing;
    height: $spacing;
    line-height: $spacing;
    padding: 0;
    border-radius: 50%;
    background: rgba(getColor(sun), 1);
    color: rgba(getColor(white), 1);

    &--sky {
        background: rgba(getColor(sky), 1);
        color: rgba(getColor(white), 1);
    }

    &--tomato {
        background: rgba(getColor(tomato), 1);
        color: rgba(getColor(white), 1);
    }

    &--neon {
        background: rgba(getColor(neon), 1);
        color: rgba(getColor(white), 1);
    }

    &--sun {
        background: rgba(getColor(sun), 1);
        color: rgba(getColor(white), 1);
    }

    &--orange {
        background: rgba(getColor(orange), 1);
        color: rgba(getColor(white), 1);
    }

    &--apple {
        background: rgba(getColor(apple), 1);
        color: rgba(getColor(white), 1);
    }

    &--lavander {
        background: rgba(getColor(lavander), 1);
        color: rgba(getColor(white), 1);
    }

    &--grey {
        background: getColor(silver-grey);
        color: rgba(getColor(white), 1);
    }
}
