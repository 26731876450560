@import 'erpcore/assets/scss/main';

.status-badge {
    $root: &;
    text-overflow: ellipsis;
    height: 17px;
    white-space: nowrap;
    position: relative;
    font: $font-caption;
    text-transform: uppercase;
    display: inline-block;
    padding: 2px 6px;
    border-radius: 3px;
    background: rgba(getColor(sky), 1);
    color: rgba(getColor(white), 1);

    &--sky {
        background: rgba(getColor(sky), 1);
        color: rgba(getColor(white), 1);
    }

    &--tomato {
        background: rgba(getColor(tomato), 1);
        color: rgba(getColor(white), 1);
    }

    &--neon {
        background: rgba(getColor(neon), 1);
        color: rgba(getColor(white), 1);
    }

    &--sun {
        background: rgba(getColor(sun), 1);
        color: rgba(getColor(white), 1);
    }

    &--orange {
        background: rgba(getColor(orange), 1);
        color: rgba(getColor(white), 1);
    }

    &--apple {
        background: rgba(getColor(apple), 1);
        color: rgba(getColor(white), 1);
    }

    &--lavander {
        background: rgba(getColor(lavander), 1);
        color: rgba(getColor(white), 1);
    }

    &--grey {
        background: getColor(silver-grey);
        color: rgba(getColor(white), 1);
    }

    .list & {
        flex-shrink: 0;
        margin: $spacing $spacing * 2 0 0;
    }
}
